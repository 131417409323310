<template>
  <div class='pa-6'>
    <v-sheet tile height='60' class='d-flex'>
      <h2>インストラクター管理</h2>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
        インストラクターを追加
      </v-btn>
    </v-sheet>
    <v-data-table
      :headers='headers'
      :items='instructors'
      sort-by='name'
      class='elevation-1'
      :items-per-page='15'
      :loading='loading'
      loading-text='読み込み中です'
      no-data-text='対象のデータがありません'
    >
      <template v-slot:item.name='{ item }'>
      {{ item.name }}
        <p
          class='mb-0 text--secondary v-messages'
          style='line-height: 1.5;'
        >
        ({{ item.nameKana }})
        </p>
      </template>
      <template v-slot:item.shops='{ item }'>
      {{ shopIdToShopName(item.shops) }}
      </template>
      <template v-slot:item.weekdayShift='{ item }'>
      {{ toAbbreviate(item.weekdayShift) }}
      </template>
      <template v-slot:item.holidayShift='{ item }'>
      {{ toAbbreviate(item.holidayShift) }}
      </template>
      <template v-slot:item.status='{ item }'>
      {{ item.status ? '有効' : '無効' }}
      </template>
      <template v-slot:item.actions='{ item }'>
        <v-btn color='error' outlined @click='edit(item)'>
          <v-icon small class='mr-2'>mdi-pencil</v-icon>
          編集
        </v-btn>
      </template>
    </v-data-table>
    <instructor-dialog v-model="dialog" @reload="reload" :action="action" :selectInstructor="selectInstructor"></instructor-dialog>
  </div>
</template>

<script>
import util from '../util';
import InstructorDialog from '../components/InstructorDialog';

export default {
  components: {
    InstructorDialog,
  },
  data: () => ({
    dialog: false,
    action: '',
    headers: [
      { text: 'インストラクター名', align: 'start', sortable: false, value: 'name' },
      { text: 'メールアドレス', sortable: false, value: 'email' },
      { text: '電話番号', sortable: false, value: 'phonenumber' },
      { text: '所属店舗', sortable: false, value: 'shops' },
      { text: '平日シフト', sortable: false, value: 'weekdayShift' },
      { text: '休日シフト', sortable: false, value: 'holidayShift' },
      { text: 'ステータス', sortable: false, value: 'status' },
      { text: '操作', sortable: false, value: 'actions' },
    ],
    instructors: [],
    selectInstructor: {},
    shops: [],
    // shopNameList: [],
    loading: false,
  }),

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      this.loading = true;
      this.shops = await util.callCloudFunction('getShop');
      // this.shopNameList = this.shops.map((obj) => obj.name);
      this.instructors = await util.callCloudFunction('getUser', { roll: 'instructor', status: 'all' });
      this.loading = false;
    },
    add() {
      this.action = 'add';
      this.selectInstructor = {};
      this.dialog = true;
    },
    edit(item) {
      this.action = 'edit';
      this.selectInstructor = item;
      this.dialog = true;
    },
    shopIdToShopName(ids) {
      const names = ids.map(id => this.shops.find((v) => v.id === id).name);
      return names.join(',  ');
    },
    toAbbreviate(data) {
      return `${data[0]} ~ ${data[data.length - 1]}`;
    },
  },
};
</script>
