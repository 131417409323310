<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>{{ actionName }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container
          id="scroll-target"
          style="max-height: 800px"
          class="overflow-y-auto"
        >
          <v-form ref='form' v-model='valid' lazy-validation :disabled='loading'>
          <v-row>
            <v-col cols='12' class='py-0' v-if='action === "edit"'>
              <p class='mb-0 text--primary'>
                アカウントステータス
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
                v-if='instructor.status'
              >
                アカウントを無効にすると、ログインできなくなります。
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
                v-else
              >
                アカウントを有効にすると、再度ログインできるようになります。
              </p>
              <v-switch
                v-model='instructor.status'
                :label="instructor.status ? '有効' : '無効'"
              ></v-switch>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                メールアドレス
                <span class='required-label' v-if='action === "add"'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                ログイン時に利用するメールアドレスです。必ずメールを受信できるアドレスを指定してください。また、メールアドレスは後から変更できません。
              </p>
              <v-text-field
                v-model='instructor.email'
                outlined
                dense
                type='email'
                validate-on-blur
                :disabled='action === "edit"'
                :rules='[formRules.required, formRules.email]'
              ></v-text-field>
            </v-col>
            <v-col cols='12' class='py-0' v-if='action === "add"'>
              <p class='mb-0 text--primary'>
                パスワード
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                8 文字以上 16 文字以内で指定してください。半角数字、半角英字をそれぞれ 1 文字以上含める必要があります。
              </p>
              <v-text-field
                v-model='instructor.password'
                outlined
                dense
                validate-on-blur
                v-bind:type="showPassword ? 'text' : 'password'"
                v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append='showPassword = !showPassword'
                :rules='[formRules.required, formRules.password]'
              ></v-text-field>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                インストラクター名
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                フリガナは全角カタカナで入力してください。
              </p>
              <v-row>
                <v-col cols='12' class='d-flex justify-space-between pb-0'>
                  <v-text-field
                    v-model='instructor.lastName'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='姓：山田'
                    :rules='[formRules.required]'
                  ></v-text-field>
                  <p class='mx-2'></p>
                  <v-text-field
                    v-model='instructor.firstName'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='名：太郎'
                    :rules='[formRules.required]'
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='d-flex justify-space-between pt-0'>
                  <v-text-field
                    v-model='instructor.lastNameKana'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='セイ：ヤマダ'
                    :rules='[formRules.required, formRules.kana]'
                  ></v-text-field>
                  <p class='mx-2'></p>
                  <v-text-field
                    v-model='instructor.firstNameKana'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='メイ：タロウ'
                    :rules='[formRules.required, formRules.kana]'
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                電話番号
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                連絡先として必要な場合は入力してください。「-」を含む半角数字で入力してください。
              </p>
              <v-text-field
                v-model='instructor.phonenumber'
                outlined
                dense
                validate-on-blur
                type='tel'
                placeholder='例）090-1234-5678'
                :rules='[formRules.tel]'
              ></v-text-field>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                所属店舗
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                複数選択可能です。
              </p>
              <v-select
                v-model='instructor.shops'
                :items=shops
                item-text="name"
                item-value="id"
                :menu-props='{ top: true, offsetY: true }'
                outlined
                chips
                deletable-chips
                multiple
                required
                :rules='[formRules.requiredArray]'
              ></v-select>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                平日シフト
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                平日シフトのテンプレートです。平日のシフト登録時に自動入力されます。
              </p>
              <v-select
                v-model='instructor.weekdayShift'
                :items=shiftRangeItems
                :menu-props='{ top: true, offsetY: true }'
                outlined
                chips
                deletable-chips
                multiple
                required
                :rules='[formRules.requiredArray]'
              ></v-select>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                休日シフト
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                休日シフトのテンプレートです。休日のシフト登録時に自動入力されます。
              </p>
              <v-select
                v-model='instructor.holidayShift'
                :items=shiftRangeItems
                :menu-props='{ top: true, offsetY: true }'
                outlined
                chips
                deletable-chips
                multiple
                required
                :rules='[formRules.requiredArray]'
              ></v-select>
            </v-col>
          </v-row>
          </v-form>
        </v-container>
        <v-alert
          type='warning'
          icon='mdi-alert-outline'
          text
          dense
          border='left'
          v-if='action === "edit"'
        >
        シフトが登録済みの日付は変更が適用されません。
        </v-alert>
        <p class='red--text' ></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined :disabled='loading' @click='close'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined @click='save' :loading='loading'>
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';

export default {
  name: 'instructor-dialog',
  props: {
    selectInstructor: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    instructor: {
      status: true,
      email: '',
      password: '',
      firstName: '',
      firstNameKana: '',
      lastName: '',
      lastNameKana: '',
      phonenumber: '',
      shops: [],
      weekdayShift: [],
      holidayShift: [],
      roll: 'instructor',
    },
    shops: [],
    shopNameList: [],
    editedIndex: -1,
    loading: false,
    valid: true,
    shiftRangeItems: [],
    showPassword: false,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず入力してください。',
      requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
      limit_length: value => (value || '').length <= 0 || '20文字以内で入力してください。',
      password: value => /^(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))[0-9a-zA-Z]{8,16}$/.test(value) || '半角数字と半角英字の2種類を含む8文字以上16文字以内で入力してください。',
      email: value => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'メールアドレスの形式が正しくありません。',
      tel: value => /^(0\d{1,4}-\d{1,4}-\d{3,4})?$/.test(value) || '電話番号の形式が正しくありません。',
      kana: value => /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/.test(value) || '全角カタカナで入力してください。',
    },
  }),
  computed: {
    actionName() {
      return this.action === 'add' ? '追加' : '編集';
    },
    positonCapacity() {
      let capacity;
      if (this.instructor.capacity) {
        capacity = [...Array(parseInt(this.instructor.capacity, 10)).keys()].map(i => i + 1);
      }
      return capacity;
    },
  },
  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
    },
    async value() {
      if (this.value) {
        this.instructor = Object.assign({}, this.instructor, this.selectInstructor);
        await this.reload();
        this.shiftRangeItems = util.timeList(0, 23);
      } else {
        this.instructor = {
          status: true,
          email: '',
          password: '',
          firstName: '',
          firstNameKana: '',
          lastName: '',
          lastNameKana: '',
          phonenumber: '',
          shops: [],
          weekdayShift: [],
          holidayShift: [],
          roll: 'instructor',
        };
      }
      this.dialog = this.value;
    },
    'instructor.name'(val) {
      if (!val) return;
      const name = val.split(' ');
      this.instructor.lastName = name[0];
      this.instructor.firstName = name[1];
    },
    'instructor.nameKana'(val) {
      if (!val) return;
      const nameKana = val.split(' ');
      this.instructor.lastNameKana = nameKana[0];
      this.instructor.firstNameKana = nameKana[1];
    },
  },
  methods: {
    async reload() {
      this.shops = await util.callCloudFunction('getShop');
      this.shopNameList = this.shops.map((obj) => obj.name);
    },
    isExistKeepTrialPosition() {
      if (!this.instructor.keepTrialPosition) return false;
      return this.instructor.keepTrialPosition.length > 0;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.selectInstructor = {};
        this.editedIndex = -1;
        this.$refs.form.resetValidation();
      });
    },
    shopNameToShopId() {
      this.instructor.shops = this.instructor.shopNames.map((name) => this.shops.find(v => v.name === name).id);
    },
    shiftSort() {
      if (this.instructor.weekdayShift) {
        this.instructor.weekdayShift = this.instructor.weekdayShift.sort();
      }

      if (this.instructor.holidayShift) {
        this.instructor.holidayShift = this.instructor.holidayShift.sort();
      }
    },
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      // this.shopNameToShopId();
      this.shiftSort();
      // this.selectInstructor.updateBy = this.userInfo.uid;
      if (this.action === 'add') {
        await util.callCloudFunction('addUser', this.instructor).then(() => {
          this.$store.commit('snackbar/open', { text: 'インストラクターは正常に登録されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch((res) => {
          const msg = !res ? 'インストラクターの登録に失敗しました。' : res;
          this.$store.commit('snackbar/open', { text: msg, color: 'error' });
        });
      } else {
        await util.callCloudFunction('updateUser', this.instructor).then(() => {
          this.$store.commit('snackbar/open', { text: 'インストラクターは正常に更新されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch(() => {
          this.$store.commit('snackbar/open', { text: 'インストラクターの更新に失敗しました。', color: 'error' });
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-messages__message {
  line-height: 13px !important;
}
</style>
